import React from "react"
import {Button, Card} from "antd"
import LinkedThemeStyle from "../less/linked-theme.module.less"
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined"
import {Link} from "gatsby-plugin-intl";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";

const {Meta} = Card

const LinkedTheme = ({title, slug}) => {
  const intl = useIntl()

  return (
    <Link to={`/project/${slug}`}>
      <Card hoverable>
        <Meta title={title}/>
        <Button className={LinkedThemeStyle.primaryButton}
                type={"link"}>{intl.formatMessage({id: 'general_discover'})}<RightOutlined/>
        </Button>
      </Card>
    </Link>
  )
}

LinkedTheme.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.number
}

LinkedTheme.defaultProps = {
  slug: 'project-1' // todo: dynamically link to the right theme
}

export default LinkedTheme
