import React from "react"
import useIntl from "../helpers/useIntl"

const TranslateHtml = ({object, prop}) => {
  const intl = useIntl()

  return (
    <div dangerouslySetInnerHTML={{ __html: intl.translate(object, prop) }} />
  )
}

export default TranslateHtml
