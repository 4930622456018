import * as React from "react"
import Layout from "../components/layout"
import ProjectDetailStyle from "../less/project-detail.module.less"
import {Button} from "antd"
import ProjectCard from "../components/project-card"
import {Link} from "gatsby-plugin-intl"
import Container from "../components/container"
import LinkedTheme from "../components/linked-theme"
import useIntl from "../helpers/useIntl"
import TranslateHtml from "../components/translateHtml"
import CardGrid from "../components/card-grid"
import SectionStyle from "../less/section.module.less"
import HeroImage from "../images/news.png"
import Jazz from "../images/jazz.png"

const ProjectDetail = ({data}) => {
  const intl = useIntl()
  const project = data.project

  return (
    <Layout heroBgColor={"white"}
            heroImage={HeroImage}
            heroTitle={intl.translate(project, "title")}
            heroSubtitle={intl.translate(project, "sub_title")}
            heroTextColor={"white"}
            heroSize={'large'}>

      <article className={SectionStyle.isHighlighted}>
        <Container size={'small'}>
          <div className={ProjectDetailStyle.article}
               style={{
                 top: '-150px',
                 position: 'relative'
               }}>
            <TranslateHtml object={project} prop={"body"}/>
          </div>
        </Container>
      </article>


      <section className={SectionStyle.isHighlighted} style={{
        paddingTop: '0',
        position: 'relative', top: '-130px'
      }}>
        <Container size={'small'}>
          <h3>{intl.formatMessage({id: 'general_related'})}</h3>
          <CardGrid>
            {
              project.fields.projectCategories.map((category, i) =>
                <LinkedTheme
                  key={i}
                  title={intl.translate(category, "title")}/>
              )
            }
          </CardGrid>
        </Container>
      </section>

      <section>
        <Container size={'small'}>
          <h3>{intl.formatMessage({id: 'projects_related'})}</h3>
          <CardGrid>
            {project.fields.relatedProjects.map((p, i) =>
              <ProjectCard
                key={i}
                title={intl.translate(p, "title")}
                slug={p.slug}
                description={intl.translate(p, "description")}
                coverImageLink={Jazz}
                coverImageAlt={''}
              />
            )}

          </CardGrid>
          <div style={{textAlign: 'right'}}>
            <Link to={'/projects'}>
              <Button
                type={'primary'}>{intl.formatMessage({id: 'projects_all'})}</Button>
            </Link>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default ProjectDetail

export const query = graphql`
  query($slug: String!) {
    project(slug: {eq: $slug}) {
      id
      slug
      title_fr
      title_nl
      sub_title_fr
      sub_title_nl
      body_fr
      body_nl
      fields {
        relatedProjects {
          name
          slug
          title_fr
          title_nl
          description_nl
          description_fr
        }
        projectCategories {
          name
          title_fr
          title_nl
        }
      }
    }
  }
`
